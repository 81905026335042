import objectFitImages from 'object-fit-images';
import {
    hoverFunc
} from "./common/_hoverFunc";
import {
    headerFunc
} from "./common/_header";
import {
    footerFunc
} from "./common/_footer";
import {
    commonFunc
} from "./common/_common";
import {
    idxFunc
} from "./pages/_index";
//* ---------------------------------------
//通常実行
//-------------------------------------- */
let setTimer = false;
//* ----------------------------------------
//window実行
//--------------------------------------- */
////// ロード時
$(window).on('load', function () {
    idxFunc();
    hoverFunc();
    headerFunc();
    footerFunc();
    $('#js-loader').fadeOut();
});

// リサイズ時
/*リサイズ後,200msで発火する設定*/
$(window).on('resize', function () {
    if (setTimer !== false) {
        clearTimeout(setTimer);
    }
    setTimer = setTimeout(() => {
        //処理
    }, 200);
});

// スクロール時
$(window).on('scroll', function () {
    if (setTimer !== false) {
        clearTimeout(setTimer);
    }
    setTimer = setTimeout(() => {
        //処理
    }, 200);
});
///* -----------------------------------------------
//ユーザーエージェント判別実行(uaFlag：1→スマホ&タブレット・2→IE・3→その他PC)
//----------------------------------------------- */
var uaFlag = 0;
if ((
        navigator.userAgent.indexOf('iPhone') > 0) ||
    (navigator.userAgent.indexOf('iPod') > 0) ||
    (navigator.userAgent.indexOf('iPad') > 0) ||
    (navigator.userAgent.indexOf('Android') > 0) ||
    (navigator.userAgent.indexOf('BlackBerry') > 0) ||
    (navigator.userAgent.indexOf('PlayBook') > 0) ||
    (navigator.userAgent.indexOf('Kindle') > 0) ||
    (navigator.userAgent.indexOf('Silk') > 0) ||
    (navigator.userAgent.indexOf('Windows Phone') > 0) ||
    (navigator.userAgent.indexOf('Nokia') > 0)
) {
    // スマホ・タブレット時の処理
    uaFlag = 1;
} else {
    // PC時の処理
    uaFlag = 3;
}
/* -----------------------------------------------
IEだけの時
----------------------------------------------- */
var userAgent = window.navigator.userAgent.toLowerCase();
if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {

}




