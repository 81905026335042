// import Swiper from "swiper";

//トップページ　キービジュアルスライダー
export function idxFunc() {
  $('.faq-dl dt').on('click', function() {
    $(this).next('dd').slideToggle();
    $(this).toggleClass('open');
  });

  $('.mitumori-btn').on('click', function() {
    $('#ai').addClass('open');
    $('body').addClass('open-ai');
  });

  $('.ai-close').on('click', function() {
    $('#ai').removeClass('open');
    $('body').removeClass('open-ai');
  });

  //スクロールアニメーション
//   var slideUp = {
//     distance: '120%',
//     origin: 'bottom',
//     opacity: null
// };
//   ScrollReveal().reveal('.scroll', slideUp);
  ScrollReveal().reveal('.scroll', {
    useDelay: 'onload',
    reset: true,
    distance: '50px'
})

}
